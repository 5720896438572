<template>
  <div >
    <a @click="$router.back()">
    <PageHeader class="primary--text text-uppercase text-left" :img="img"
    :pagetitle="this.$route.params.Table" /></a>
    
    <!--<PageHeader :pagetitle="this.$route.params.Table" :img="img"/>   
    <v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->
   
  <v-container fluid>
  <v-card
    elevation="5"
    outlined
    rounded
    max-height="100%"
    class="justify-center"
  > 
  <v-row>
    <v-col
      v-for="(item, i) in this.TableData"   
      :key="i"
      cols="12"
    >
    <v-card-title class="text-h5 font-weight-bold ">
      {{item.Name}}
    </v-card-title>
    <v-card-subtitle class="font-weight-bold">
      {{item.Address}}
    </v-card-subtitle>
    <v-card-subtitle class="text-h6 font-weight-bold">
      {{item.Content}}
    </v-card-subtitle>
    </v-col>  
  </v-row>
  </v-card>
  
    <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
      <swiper-slide
          v-for="(rowImage, j) in this.ImagesData"   
          :key="j"
      >
      <v-container>
      <v-card
        elevation="15"
        class="mx-auto justify-center"
        :max-width="$vuetify.breakpoint.mdAndUp ? 600 : 200"
        :max-height="500"
        :height="$vuetify.breakpoint.mdAndUp ? 300 : 150"
        :width="$vuetify.breakpoint.mdAndUp ? 360 : 200"
        hover
        raised
        
      >
     
     
        <EnlargeableImage
          :src="$api_url+rowImage.url" 
          :src_large="$api_url+rowImage.url" 
          :src_height="$vuetify.breakpoint.mdAndUp ? 300 : 150"
          :src_width="$vuetify.breakpoint.mdAndUp ? 360 : 200"
        />
     
       <!--
      <v-img
        contain
        max-height="400"
        max-width="600"
        :height="$vuetify.breakpoint.mdAndUp ? 280 : 200"
        :width="$vuetify.breakpoint.mdAndUp ? 360 : 200"
        :src="$api_url+rowImage.url" 
        :lazy-src="$api_url+rowImage.url"
        @click="testalert()"
      >
      </v-img > 
       -->
      </v-card>
      </v-container>
     

    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
 
  <Footer v-if="this.$route.name !== 'Home'"> </Footer>
 <v-spacer />
  </v-container>

  
  </div>
</template>

<script>

import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'


import axios from 'axios' 
//import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'
import EnlargeableImage from '@/components/EnlargeableImage';
import PageHeader from '@/components/PageHeader'


export default {

  name : 'ViewContent',
    components: {
    //TitleComponent,
    PageHeader,
    Footer,
    EnlargeableImage,
    Swiper,
    SwiperSlide,
  },
 
  data () {
    return {
        img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
        imgHeight:0,
        imgWidth:0,
        show: false,
        items:[],
        TableData:[],
        ImagesData:[],
        swiperOptions: {
          slidesPerView: (this.$vuetify.breakpoint.mdAndUp ? 5 : 1 ),
          spaceBetween: 10,
          slidesPerColumn: (this.$vuetify.breakpoint.mdAndUp ? 3 : 1 ),
          pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
          // make 1 sb members per slide in smartphones
          //(this.$vuetify.breakpoint.mdAndUp ? 11 : 1),
        }
     
    }

    },

    mounted(){
      //alert(this.$route.params.ID);
      this.getAllRecords(`/getAllRecords/`+this.$route.params.Table+`/where ID = `+this.$route.params.ID+` /ID asc`);
      this.displayTableimages(this.$route.params.ID)
      //alert(this.ImagesData);
      this.swiper.slideTo(3, 1000, false); 
     

    },  

    updated(){

     
    },

    computed: {
    
    },

    methods:{

     testalert:function(){
       alert()
     },
    
      getAllRecords:function(query){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.TableData=response.data;
              //return this.items
            }
      })
      .catch(error => { console.log(error)})
    },  


     async displayTableimages(id){
        //alert(projectid);
       
        try {
          const { data } = await axios.get(
           this.$api_url+`/displayTableimages/${this.$route.params.Table.toLowerCase()}/${id}`,
            );
          //alert(data);
          this.ImagesData = data;
          //alert(this.ImagesData);
          console.log(this.ImagesData);
          
          
        } catch (error) {
          console.log(error);
        }
    },  


    formatData:function(value,type){
      //alert(value);
      //!isNaN(Date.parse(value)) 
      //alert(typeof value)
      
      var findchar=value.toString().search('-');
      if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
        if(type=="integer"){
          return Number(value)
        }else if(type=="text"){
          return value
        }
        else if (type=="select"){

          return value 
          //get name of the value in the dependent table
        
        
        //di mapagana
        
        
        /*let name = (async() =>{
        let output = await Promise.resolve(this.getDependentRow(fieldname,value));
            return output;
        })();

        (async () => {
          console.log(await name);
        })();
        */
        /*
        name().then((result)=>{
            console.log(result);
            
        });
        */
        //return name();
          
    
      
        
        /*const retval=this.getDependentRow(fieldname,value).finally(function (retval) {
            //alert(data)
            
            console.log(retval)
            
            return retval
          })*/

          //return retval
        }
        else{
          return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        
      }
      else if( !isNaN(Date.parse(new Date(value))) ){
        findchar=value.toString().search('/');
        if(findchar < 0){
          return this.formatDate(value);
        }else{
          return value
        }
      
      }
      else{
        return value
      }
      
    },
    formatDate:function(value) {
        if ( (value !== '0000-00-00') && (value) ) {
          //alert(value);
          const date = new Date(value)
          return date.toLocaleDateString(['en-US'], {month: 'short', day: '2-digit', year: 'numeric'})  
          //if you want, you can change locale date string
      }
      else{
          if (value==null){
              value='0000-00-00';
          }
          return value
      }
    },
  }
}

</script>

<style lang="scss" >

 @import 'swiper/swiper.scss';
   .swiper {
    height: 800px;
    margin-left: auto;
    margin-right: auto;

    .swiper-slide {
      height: 350px;
    }
  };



.v-image__image:hover{
  height: 100%;
  width:100%
 //-webkit-transform:scale(1.5); /* or some other value */
 //   transform:scale(1.5);

}
</style>